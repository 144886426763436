import {
  IonImg,
  IonItem,
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { DataContext } from "../context/DataContext";

import bei_stoerungen from "../images/bei_stoerungen.jpg";
import logo_hgw from "../images/logo_hgw.jpg";
import logo_dgti from "../images/logo_dgti.png";
import logo_smartparking from "../images/logo_smartparking.png";
import { Capacitor } from "@capacitor/core";
import appStoreBadge from '../images/Download_on_the_App_Store_Badge_DE_RGB_blk_092917.svg' ;
import playStoreBadge from '../images/google-play-badge.png' ;

import "./CompFavorites.css";

interface ContainerProps {}

const CompFavorites: React.FC<ContainerProps> = () => {
  const {  } = useContext(DataContext);

  const [ storeBadges , setStoreBadges ]:any = useState( null ) ;

  useEffect(
    function toggleStoreBadges()
      {
        let my_badges = null ;
        let my_badgeAppStore = null ;
        let my_badgePlayStore = null ;
        if( Capacitor.getPlatform() === 'web' ) {
          my_badgeAppStore =
            <a
              href = "https://apps.apple.com/de/app/parken-greifswald/id6447558274"
              target = "_blank"
            >
              <IonImg
                slot = 'start'
                src = { appStoreBadge }
              />
            </a>
          my_badgePlayStore =
            <a
              href = "https://play.google.com/store/apps/details?id=com.solufind.pp.greifswald"
              target = "_blank"
            >
              <IonImg
                slot = 'start'
                src = { playStoreBadge }
              />
            </a>
          my_badges =
            <>
              { my_badgeAppStore }
              { my_badgePlayStore }
            </>
        }
        setStoreBadges( my_badges ) ;
      }
    ,
    []
  ) ;
  
  return (
    <IonItem key="favorites" style={{ AlignCenter: "center" }}>
      <a href="https://www.greifswald.de" target="_blank"><img slot="start" src={logo_hgw} className="fav_greifswald" /></a>
      <a href="tel:03834898581" target="_blank"><img slot="start" src={bei_stoerungen} className="fav_stoerungen" /></a>

      <a onClick={()=>{alert('Die Akzeptanz des dgti-Ergänzungsausweises wird gewährleistet. Der Ergänzungsausweis ist nur gültig in Kombination mit einem amtlichen Personaldokument.')}} target="_blank"><img slot="start" src={logo_dgti} className="fav_dgti" /></a>

      <a onClick={()=>{alert('An allen städtischen Parkscheinautomaten, die mit einem entsprechenden Aufkleber gekennzeichnet sind, ist die Zahlung der Parkgebühr über die jeweilige App möglich.')}} target="_blank"><img slot="start" src={logo_smartparking} className="fav_smartparking" /></a>

      { storeBadges }

    </IonItem>
  );
};

export default CompFavorites;
