import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonIcon,
  IonLabel,
  IonImg,
} from "@ionic/react";
import CompKarte from "../components/CompKarte";

import { chevronForward } from "ionicons/icons";

import iconBus from "../images/icon_bus_schwarz.svg" ;
import iconCar from "../images/icon_auto_schwarz.svg" ;
import iconCaravan from "../images/icon_womo_schwarz.svg" ;
import iconCharingStation from "../images/icon_elektro_schwarz.svg" ;
import iconWheelchair from "../images/icon_behindertenparkplatz_schwarz.svg" ;
import iconPersonDigging from "../images/icon_baustelle_schwarz.svg" ;
import iconMotorrad from "../images/icon_motorrad_schwarz.svg" ;
import iconFahrrad from "../images/icon_fahrrad_schwarz.svg" ;
import iconKartenzahlung from "../images/icon_kartenzahlung.svg" ;
import iconTarifzonen from '../images/icon_zone_schwarz.svg' ;

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/css/navigation";
import './PageKarte.css' ;
import { Navigation } from "swiper";
import "@ionic/react/css/ionic-swiper.css";
import { DataContext } from "../context/DataContext";
import { useContext, useEffect, useState,  } from "react";
import CompFavorites from "../components/CompFavorites";

const PageKarte: React.FC = () => {

  const {
    appStellplaetzeGiven ,
    appBaustellen ,
    appFilterPKW,
    appFilterPKWChange,
    appFilterLKW,
    appFilterLKWChange,
    appFilterBehindert,
    appFilterBehindertChange,
    appFilterBusse,
    appFilterBusseChange,
    appFilterElektro,
    appFilterElektroChange,
    appFilterMotorraeder,
    appFilterMotorraederChange,
    appFilterFahrraeder,
    appFilterFahrraederChange,
    appFilterKartenzahlung ,
    appFilterKartenzahlungChange ,
    appFilterWoMo,
    appFilterWoMoChange,
    appFilterBaustelle,
    appFilterBaustelleChange ,
    appFilterTarifzonen,
    appFilterTarifzonenChange,
    appNewsItems
  } = useContext(DataContext);

  const [ swiperGallery , setSwiperGallery ] = useState( <div></div> );

  function toggleFilter(State: any, StateFunktion: Function) {
    if (State) {
      StateFunktion(false);
    } else {
      StateFunktion(true);
    }
  }

  useEffect(
    function initSwiperGallery() {
      if( appNewsItems.length > 0 ) {
        let my_swiperGallery =
          <Swiper
            breakpoints =
              {
                {
                  1024:
                    {
                      slidesPerView: 3
                    }
                  ,
                  768:
                    {
                      slidesPerView: 2
                    }
                }
              }
            className="mySwiper"
            loop={true}
            modules = { [ Navigation ] }
            navigation = { true }
          >
            {
              appNewsItems
                .map(
                  ( entry:any , index:number ) =>
                    {
                      return(
                        <SwiperSlide key = { 'news_' + index } >
                          <IonCard
                            href={ '/news/' + encodeURIComponent( entry[ 2 ] ) + '_' + entry[ 0 ]  }
                          >
                           
                            <div className="news_vorschau" style =
                              {
                                {
                                  background: "url('"+entry[ 5 ]+"') no-repeat",
                                  backgroundSize:'100% auto',
                                  backgroundPosition: "center center",
                                  height:'250px',
                                  minHeight:'250px',
                                  maxHeight:'250px',
                                }
                              }>
                               
                               &nbsp;
                            </div>
                            <div
                              style =
                                {
                                  {
                                    minHeight: '150px' ,
                                    maxHeight: '150px' ,
                                    overflow: 'hidden'
                                  }
                                }
                            >
                              <IonCardHeader>
                                <IonCardTitle>{ entry[ 2 ] }</IonCardTitle>
                                <IonCardSubtitle>{ new Date( entry[ 3 ] ).toLocaleDateString() }</IonCardSubtitle>
                              </IonCardHeader>
                              <IonCardContent>
                                &nbsp;
                              </IonCardContent>
                            </div>
                            <IonButton
                              color="tertiary"
                              expand="block"
                              routerLink = { '/news/' + encodeURIComponent( entry[ 2 ] ) + '_' + entry[ 0 ]  }
                            >
                              weiterlesen
                              <IonIcon slot="end" icon={chevronForward}></IonIcon>
                            </IonButton>
                          </IonCard>
                        </SwiperSlide>
                      )
                    }
                  )
            }
          </Swiper>
        ;
        setSwiperGallery( my_swiperGallery ) ;
      }
    }
    ,
    [ appNewsItems ]
  )

  return (
    
    <>
      <IonCard key="karte">
        <IonCardHeader>
          <IonCardTitle style={{ textAlign: "left" }}>
            <IonLabel style = {{ float: 'left' }}>Fahrzeugtyp wählen: </IonLabel>
            {
              appStellplaetzeGiven.includes( 'pkws' )
                ?
                  <IonImg
                    style = {{ float: 'left' , height: '1.2em' , opacity: appFilterPKW ? '1' : '0.2' , margin: '0.2em' }}
                    src = { iconCar }
                    title = 'Parkräume für PKWs anzeigen'
                    onClick={() => toggleFilter(appFilterPKW, appFilterPKWChange)}
                  />
                :
                 null
            }
            {
              appStellplaetzeGiven.includes( 'wohnmobile' )
                ?
                  <IonImg
                    style = {{ float: 'left' , height: '1.2em' , opacity: appFilterWoMo ? '1' : '0.2' , margin: '0.2em'  }}
                    src = { iconCaravan }
                    title = 'Parkräume für Wohnmobile anzeigen'
                    onClick={() => toggleFilter(appFilterWoMo, appFilterWoMoChange)}
                  />
                :
                  null
            }
            {
              appStellplaetzeGiven.includes( 'busse' )
                ?
                  <IonImg
                    style = {{ float: 'left' , height: '1.2em' , opacity: appFilterBusse ? '1' : '0.2' , margin: '0.2em'  }}
                    src = { iconBus }
                    title = 'Parkräume für Busse anzeigen'
                    onClick={() => toggleFilter(appFilterBusse, appFilterBusseChange)}
                  />
                :
                  null
            }
            {
              appStellplaetzeGiven.includes( 'elektro' )
                ?
                  <IonImg
                    style = {{ float: 'left' , height: '1.2em' , opacity: appFilterElektro ? '1' : '0.2' , margin: '0.2em'  }}
                    src = { iconCharingStation }
                    title = 'Parkräume mit E-Stationen anzeigen'
                    onClick={() => toggleFilter(appFilterElektro, appFilterElektroChange)}
                  />
                :
                  null
            }
            {
              appStellplaetzeGiven.includes( 'behindertengerecht' )
                ?
                  <IonImg
                    style = {{ float: 'left' , height: '1.2em' , opacity: appFilterBehindert ? '1' : '0.2' , margin: '0.2em'  }}
                    src = { iconWheelchair }
                    title = 'Behindertengerechte Parkräume anzeigen'
                    onClick={() => toggleFilter(appFilterBehindert, appFilterBehindertChange)}
                  />
                :
                  null
            }
            {
              appStellplaetzeGiven.includes( 'motorraeder' )
                ?
                  <IonImg
                    style = {{ float: 'left' , height: '1.2em' , opacity: appFilterMotorraeder ? '1' : '0.2' , margin: '0.2em'  }}
                    title = 'Parkräume für Motorräder anzeigen'
                    src = { iconMotorrad }
                    onClick={() => toggleFilter(appFilterMotorraeder, appFilterMotorraederChange)}
                  />
                :
                  null
            }
            {
              appStellplaetzeGiven.includes( 'fahrraeder' )
                ?
                  <IonImg
                    style = {{ float: 'left' , height: '1.2em' , opacity: appFilterFahrraeder ? '1' : '0.2' , margin: '0.2em'  }}
                    src = { iconFahrrad }
                    title = 'Parkräume für Fahrräder anzeigen'
                    onClick={() => toggleFilter(appFilterFahrraeder, appFilterFahrraederChange)}
                  />
                :
                  null
            }
            {
              appStellplaetzeGiven.includes( 'kartenzahlung' )
                ?
                  <IonImg
                    style = {{ float: 'left' , height: '1.2em' , opacity: appFilterKartenzahlung ? '1' : '0.2' , filter: appFilterKartenzahlung ? 'invert(24%) sepia(91%) saturate(6505%) hue-rotate(121deg) brightness(96%) contrast(104%)' : '', margin: '0.2em'  }}
                    src = { iconKartenzahlung }
                    title = 'Nur Parkräume mit Kartenzahlungsmöglichkeit anzeigen'
                    onClick={() => toggleFilter(appFilterKartenzahlung, appFilterKartenzahlungChange)}
                  />
                :
                  null
            }
            <IonImg
              style = {{ float: 'left' , height: '1.2em' , opacity: appFilterTarifzonen ? '1' : '0.2', margin: '0.2em' }}
              src = { iconTarifzonen }
              title = 'Tarifzonen anzeigen'
              onClick={() => toggleFilter(appFilterTarifzonen, appFilterTarifzonenChange)}
            />
            <IonImg
              style = {{ float: 'right' , height: '1.2em' , opacity: appFilterBaustelle && appBaustellen.length > 0 ? '1' : '0.2' , filter: appFilterBaustelle && appBaustellen.length > 0 ? 'invert(56%) sepia(82%) saturate(5557%) hue-rotate(328deg) brightness(96%) contrast(92%)' : '' , margin: '1vh'  }}
              src = { iconPersonDigging }
              title = 'Baustellen anzeigen'
              onClick = 
                {
                  () => {
                    if( appBaustellen.length > 0 ) {
                      toggleFilter(appFilterBaustelle, appFilterBaustelleChange)
                    }
                    else {
                      alert( 'Aktuell keine Baustellen bekannt' ) ;
                    }
                  }
                }
            />
            <br style={{ clear: "both" }} />
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent
          style =
            {
              {
                padding: '0'
              }
            }
        >
          <CompKarte />
          <CompFavorites />

        </IonCardContent>
      </IonCard>
      <IonCard key="newsswiper">
        <IonCardContent
          style =
            {
              {
                padding: '0'
              }
            }
        >
          { swiperGallery }
        </IonCardContent>
      </IonCard>
    </>
  );
};

export default PageKarte;
