import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonItem,
  IonList,
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { DataContext } from "../context/DataContext";


const PageTarife = () => {
  const {
    appTarife
  } = useContext( DataContext ) ;

  const [ generatedEntries , setGeneratedEntries ] = useState( [ <span key = "entries_placeholder" ></span> ] );

  useEffect(
    function makeEntries()
      {
        if( appTarife.length > 0 ){
          let my_items =
            appTarife
              .map((entry:any)=>{
                return (
                  <IonItem 
                    key={'tarif'+entry[0]} 
                    routerLink={'/tarife/'+entry[2]+'_'+entry[0]}
                  >
                    {entry[2]}
                  </IonItem>
                )
                }
              )
          ;
          setGeneratedEntries( my_items ) ;
        }
      }
    ,
    [ appTarife ]
  ) ;

  return (
    <>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>Tarife & Gebühren</IonCardTitle>
        </IonCardHeader>

        <IonCardContent>
          <IonList>
            { generatedEntries }
          </IonList>
        </IonCardContent>
      </IonCard>
    </>
  );
};

export default PageTarife;
