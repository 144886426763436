import {
  IonButton,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonList,
  IonModal,
  IonRow,
  IonToolbar,
} from "@ionic/react";
import { useContext, useEffect, useRef, useState } from "react";
import { MapContainer, Marker, Polygon, Popup, TileLayer, useMap } from "react-leaflet";
import { useLeafletContext } from '@react-leaflet/core' ;
import 'leaflet-easybutton' ;

import L from "leaflet";

import "./CompKarte.css";

import iconBus from "../images/icon_bus_schwarz.svg" ;
import iconCar from "../images/icon_auto_schwarz.svg" ;
import iconCaravan from "../images/icon_womo_schwarz.svg" ;
import iconCharingStation from "../images/icon_elektro_schwarz.svg" ;
import iconWheelchair from "../images/icon_behindertenparkplatz_schwarz.svg" ;
import iconTruck from "../images/icon_lkw_schwarz.svg" ;
import iconMotorrad from "../images/icon_motorrad_schwarz.svg" ;
import iconFahrrad from "../images/icon_fahrrad_schwarz.svg" ;
import iconKartenzahlung from "../images/icon_kartenzahlung.svg" ;
import { DataContext } from "../context/DataContext";
import { closeOutline } from "ionicons/icons";
import { ContentParkraum } from "../contents/ContentParkraum";
import { ContentTarifzone } from "../contents/ContentTarifzone";

const svgFaHouse = `<svg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='house' class='svg-inline--fa fa-house fa-2x ' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' style = ' vertical-align: middle; ' ><path fill='currentColor' d='M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c0 2.7-.2 5.4-.5 8.1V472c0 22.1-17.9 40-40 40H456c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1H416 392c-22.1 0-40-17.9-40-40V448 384c0-17.7-14.3-32-32-32H256c-17.7 0-32 14.3-32 32v64 24c0 22.1-17.9 40-40 40H160 128.1c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2H104c-22.1 0-40-17.9-40-40V360c0-.9 0-1.9 .1-2.8V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z'></path></svg>` ;

const svgFaUndo = `<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-rotate-left" class="svg-inline--fa fa-arrow-rotate-left fa-2x " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"  style = ' vertical-align: middle; ' ><path fill="currentColor" d="M109.7 160H160c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V64C0 46.3 14.3 32 32 32s32 14.3 32 32v51.2L81.6 97.6c87.5-87.5 229.3-87.5 316.8 0s87.5 229.3 0 316.8s-229.3 87.5-316.8 0c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0c62.5 62.5 163.8 62.5 226.3 0s62.5-163.8 0-226.3s-163.8-62.5-226.3 0L109.7 160z"></path></svg>`


const appSettings =
  {
    leaflet:
      {
        center: [ 54.0928368 , 13.3792729 ] ,
        initialZoom:
          {
            breakpoints:
              {
                480: 14 ,
                0: 13
              }
          }
        ,
        maxBounds:
          [
            [ 54.14313233476031 , 13.581504821777346 ] ,
            [ 54.04245742542455 , 13.176727294921877 ]
          ]
        ,
        maxBoundsViscosity: 1.0 ,
        maxZoom: 18 ,
        minZoom: 12 ,
      }
  }

const adjust_initial_zoom = () =>
  {
    let my_numericKeys =
      Object.keys( appSettings.leaflet.initialZoom.breakpoints )
        .map(
          ( entry:string ) => Number( entry )
        )
        .sort( ( a , b ) => b - a )
    ;
    for( let i = 0 ; i < my_numericKeys.length ; i++ ) {
      if( window.screen.availWidth > my_numericKeys[ i ] ){
        // @ts-ignore
        return appSettings.leaflet.initialZoom.breakpoints[ my_numericKeys[ i ] ] ;
      }
    }
  }

const mapSettings =
  {
    leaflet:
      {
        center: appSettings.leaflet.center ,
        initialZoom: adjust_initial_zoom() ,
        maxBounds: appSettings.leaflet.maxBounds ,
        maxBoundsViscosity: appSettings.leaflet.maxBoundsViscosity ,
        maxZoom: appSettings.leaflet.maxZoom ,
        minZoom: appSettings.leaflet.minZoom ,
      }
  }



const svgParkenschild = `<?xml version="1.0" encoding="utf-8"?>
<!-- Generator: Adobe Illustrator 25.4.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg style="height:2em;" version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 28 27.4" style="enable-background:new 0 0 28 27.4;" xml:space="preserve">
<style type="text/css">
	.st0{fill:#00519C;}
	.st1{fill:#FFFFFF;}
</style>
<g id="Ebene_2_00000136388303607737902790000007035960073175070137_">
	<path class="st0" d="M3.2,0h21.6C26.6,0,28,1.4,28,3.2v21c0,1.8-1.4,3.2-3.2,3.2H3.2C1.4,27.4,0,26,0,24.2v-21C0,1.4,1.4,0,3.2,0z"
		/>
</g>
<g id="Ebene_1_00000053515268392043366050000015940762546100608191_">
	<g>
		<path class="st1" d="M20.5,4.7c-0.8-0.6-1.6-1.1-2.6-1.3c-0.9-0.3-1.8-0.3-6.4-0.3H7.1v10.7v10.7h4.2v-6.9h2.2c1.2,0,2.6,0,3,0
			c1.2-0.1,2.4-0.5,3.5-1.2c2.9-2,4-5.8,2.5-9C22,6.3,21.4,5.4,20.5,4.7z M18.9,11.6c-0.5,1-1.4,1.7-2.5,1.8l0,0
			c-0.3,0.1-1.6,0.1-2.9,0.1h-2.3V7H14c2.6,0,2.8,0,3.1,0.2c0.8,0.3,1.5,1,1.9,1.8c0.2,0.4,0.2,0.8,0.2,1.2
			C19.3,10.7,19.2,11.2,18.9,11.6z"/>
	</g>
</g>
</svg>` ;

const svgBaustellenschild = `<?xml version="1.0" encoding="utf-8"?>
<!-- Generator: Adobe Illustrator 25.4.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg style="height:2em;" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 28 27.4" style="enable-background:new 0 0 28 27.4;" xml:space="preserve">
<style type="text/css">
	.st0_bst{fill:#EB445A;}
	.st1{fill:#FFFFFF;}
</style>
<g id="Ebene_1">
	<g id="Ebene_2_00000034792919065910212490000004589823888918165134_">
		<polyline class="st0_bst" points="0,26.2 13.9,1.2 28,26.2 		"/>
		<polyline class="st1" points="2.7,24.5 13.9,5 24.9,24.2 		"/>
	</g>
</g>
<g id="Ebene_2">
	<path id="path4038_00000031924534799754347810000010967744454224433821_" d="M7.6,23.1c-0.2-0.2-0.3-0.5-0.4-0.8
		c0-0.3-0.1-0.1,1.6-3.3l1.2-2.2l0.7,0.4c1.1,0.6,1,0.5,1.6,1.4c0.3,0.5,0.6,0.9,0.6,0.9c0,0,0.1,0.7,0.1,1.6c0,0.8,0.1,1.7,0.1,1.9
		l0,0.4H13c-0.4,0-0.8-0.3-1-0.6c-0.1-0.2-0.1-0.3-0.2-1.5l-0.1-1.3l-0.6-0.9C11,18.4,10.7,18,10.7,18c0,0-0.7,1.2-1.4,2.6
		c-0.8,1.4-1.4,2.6-1.4,2.6C7.8,23.2,7.7,23.2,7.6,23.1L7.6,23.1z M14,23.1c-0.1-0.1-0.1-0.2,0-0.4c0.1-0.2,1.5-1.8,1.7-2
		c0.1-0.1,0.2-0.1,0.4-0.2c0.2-0.1,0.3-0.1,0.4-0.2c0.1-0.1,0.4-0.5,0.8-0.9c0.4-0.5,0.7-0.8,0.9-0.9c0.3-0.2,0.7-0.3,1-0.2
		c0.5,0.1,0.6,0.2,2,2.1c0.7,0.9,1.4,1.8,1.5,2c0.3,0.4,0.3,0.5,0.2,0.6l-0.1,0.1h-4.4C14.2,23.2,14,23.2,14,23.1L14,23.1z M15,19.5
		l-1.2-1l0,0.2l0,0.2l-0.2,0c-0.4-0.1-0.8-0.5-0.8-1v-0.3l-0.3-0.3l-0.3-0.3l0.1-0.2c0.1-0.1,0.1-0.2,0.1-0.2c0,0,0.1,0.1,0.3,0.2
		l0.2,0.2V17c0-0.1,0-0.5,0-0.8c0-0.4,0-0.7,0-0.7c0,0-0.1,0.2-0.2,0.4c-0.1,0.2-0.2,0.5-0.3,0.5c0,0-0.1,0.2-0.3,0.5
		c-0.1,0.2-0.2,0.4-0.3,0.5c0,0-0.4-0.2-0.9-0.4l-0.9-0.5l0.1-0.1c0-0.1,0.4-0.7,0.8-1.5c0.4-0.7,0.7-1.4,0.7-1.4c0,0-0.2,0-0.5,0
		h-0.5L10.4,14c-0.2,0.3-0.3,0.6-0.3,0.7c0,0.1,0.1,0.2,0.3,0.3l0.3,0.3l-0.1,0.2c0,0.1-0.1,0.2-0.1,0.2c0,0-0.2-0.1-0.4-0.2
		c-0.2-0.1-0.3-0.3-0.3-0.2c0,0-0.1,0.1-0.2,0.3c-0.1,0.1-0.2,0.2-0.2,0.2c-0.1,0-0.3-0.3-0.4-0.5c-0.2-0.4-0.1-0.5,0.4-1.5
		c0.5-0.9,0.6-1.1,0.8-1.2c0.2-0.1,2.9-0.1,3.1,0c0.1,0,0.3,0.1,0.4,0.3c0.4,0.4,0.4,0.3,0.2,2.9c-0.1,1.8-0.1,2.2-0.1,2.3
		c0,0,0.6,0.5,1.3,1.1c0.7,0.6,1.3,1.1,1.3,1.1c0,0-0.3,0.3-0.3,0.3C16.2,20.4,15.6,20,15,19.5L15,19.5z M14.8,12.9
		c-0.6-0.1-1-0.7-0.8-1.3c0.1-0.3,0.3-0.5,0.6-0.7c0.6-0.3,1.2,0,1.4,0.6c0.2,0.4,0.1,0.8-0.2,1.2C15.5,12.9,15.2,13,14.8,12.9
		L14.8,12.9z"/>
</g>
</svg>
` ;

const svgParkhaus = `<?xml version="1.0" encoding="utf-8"?>
<!-- Generator: Adobe Illustrator 25.4.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg style="height:2em;" version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 28 27.4" style="enable-background:new 0 0 28 27.4;" xml:space="preserve">
<style type="text/css">
	.st0{fill:#00519C;}
	.st1{fill:#FFFFFF;}
</style>
<g id="Ebene_2_00000017496368814303576400000008415543433622684601_">
	<path class="st0" d="M3.2,0h21.6C26.6,0,28,1.4,28,3.2v21c0,1.8-1.4,3.2-3.2,3.2H3.2C1.4,27.4,0,26,0,24.2v-21C0,1.4,1.4,0,3.2,0z"
		/>
</g>
<g id="Ebene_1_00000054988887151528201120000015716427928454651051_">
	<g id="Ebene_2_00000081630700172779947090000006129755324616378792_">
		<g id="svg4993">
			<path id="path3893" class="st1" d="M7.1,16.8V6.1h4.4c4.6,0,5.4,0,6.4,0.3c0.9,0.3,1.8,0.7,2.6,1.3c0.9,0.7,1.5,1.6,2,2.6
				c1.5,3.2,0.4,7-2.5,9c-1,0.7-2.2,1.1-3.5,1.2c-0.4,0-1.8,0-3,0h-2.2v6.9H7.1V16.8z M16.5,16.4c1.1-0.2,2-0.8,2.5-1.8
				c0.2-0.4,0.3-0.9,0.3-1.3c0-0.4,0-0.8-0.2-1.2c-0.4-0.8-1-1.5-1.9-1.9C16.8,10,16.7,10,14.1,10h-2.7v6.5h2.3
				C14.9,16.5,16.2,16.5,16.5,16.4L16.5,16.4z M0.4,6.2C0.2,5.7,0,5.2,0,5.1s2-0.8,7-2.6L14,0l7,2.5c3.8,1.4,7,2.5,7,2.6
				s-0.6,2-0.7,2.1l-6.7-2.4L14,2.4L7.5,4.8L0.8,7.2C0.7,7.2,0.6,7,0.4,6.2z"/>
		</g>
	</g>
</g>
</svg>`
;

var parkplatzIcon = L.divIcon({className: 'my-div-icon',html: svgParkenschild});
var parkhausIcon = L.divIcon({className: 'my-div-icon',html: svgParkhaus});
var stellplatzIcon = L.divIcon({className: 'my-div-icon',html: svgParkenschild });
var baustelleIcon = L.divIcon({className: 'my-div-icon', html: svgBaustellenschild });

const tarifzonenFarben =
  [
    'yellow' ,
    'blue' ,
    'red'
  ]

const CompKarte: React.FC = () => {
  const {
    appParkhausschilder ,
    appParkraeume ,
    appBaustellen ,
    appTarife ,
    appFilterPKW,
    appFilterLKW,
    appFilterBehindert,
    appFilterBusse,
    appFilterElektro,
    appFilterMotorraeder,
    appFilterFahrraeder,
    appFilterKartenzahlung ,
    appFilterWoMo,
    appFilterBaustelle ,
    appFilterTarifzonen
} = useContext(DataContext);

  const [renderMap, setRenderMap] = useState(false);
  const [ mapBounds , setMapBounds ]:any = useState( [] );
  const [ mapBoundsInitial , setMapBoundsInitial ]:any = useState();
  const [ mapBoundsMax , setMapBoundsMax ]:any = useState();
  const [ mapCenter , setMapCenter ] = useState( mapSettings.leaflet.center );
  const [parkplaetzeServer, setParkplaetzeServer] = useState([]);
  const [BaustellenServer, setBaustellenServer] = useState([]);
  const [ baustellenMarker , setBaustellenMarker ]:any = useState( null ) ;
  const [ TarifzonenServer , setTarifzonenServer ] = useState( [] ) ;
  const [parkplaetzeAnzeige, setParkplaetzeAnzeige] = useState(Array);
  const [ parkplaetzeMarker , setParkplaetzeMarker ]:any = useState( null ) ;
  const [showParkplatzModal, setShowParkplatzModal] = useState(false);
  const [ modalContent , setModalContent ]:any = useState() ;
  const [ modalTitle , setModalTitle ]:any = useState( '' ) ;
  const [parkplatzAktiv, setParkplatzAktiv] = useState();
  const [showTarifzoneModal, setShowTarifzoneModal] = useState(false);
  const [tarifzoneAktiv, setTarifzoneAktiv] = useState();
  const [ tarifzonenPolygons , setTarifzonenPolygons ]:any = useState( null ) ;
  const [ modalTarifzoneContent , setModalTarifzoneContent ]:any = useState() ;
  const [ modalTarifzoneTitle , setModalTarifzoneTitle ]:any = useState( '' ) ;
  
  const mkPopupParkraum = ( parkplatz:any ) =>
    {
      return (
        <Popup
          minWidth = { 200 }
        >
          <IonGrid
            style =
              {
                {
                  padding: '0'
                }
              }
          >
            <IonRow>
              <IonCol
                style =
                  {
                    {
                      fontSize: '1.5em' ,
                      fontWeight: 'bold' ,
                      padding: '0'
                    }
                  }
              >
                {parkplatz[3]}
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                {parkplatz[5] > 0 ? (
                  <IonImg
                    style = {{ float: 'left' , height: '2em' , margin: '0.2em' }}
                    src = { iconCar }
                  />
                ) : null}
                {parkplatz[6] > 0 ? (
                  <IonImg
                    style = {{ float: 'left' , height: '2em' , margin: '0.2em' }}
                    src = { iconTruck }
                  />
                ) : null}
                {parkplatz[7] > 0 ? (
                  <IonImg
                    style = {{ float: 'left' , height: '2em' , margin: '0.2em' }}
                    src = { iconCaravan }
                  />
                ) : null}
                {parkplatz[8] > 0 ? (
                  <IonImg
                    style = {{ float: 'left' , height: '2em' , margin: '0.2em' }}
                    src = { iconBus }
                  />
                ) : null}

                {parkplatz[9] > 0 ? (
                  <IonImg
                    style = {{ float: 'left' , height: '2em' , margin: '0.2em' }}
                    src = { iconCharingStation }
                  />
                ) : null}
                {parkplatz[10] > 0 ? (
                  <IonImg
                    style = {{ float: 'left' , height: '2em' , margin: '0.2em' }}
                    src = { iconWheelchair }
                  />
                ) : null}
                {parkplatz[11] > 0 ? (
                  <IonImg
                    style = {{ float: 'left' , height: '2em' , margin: '0.2em' }}
                    src = { iconMotorrad }
                  />
                ) : null}
                {parkplatz[15] > 0 ? (
                  <IonImg
                    style = {{ float: 'left' , height: '2em' , margin: '0.2em' }}
                    src = { iconFahrrad }
                  />
                ) : null}
                {parkplatz[17] === 'true' ? (
                  <IonImg
                    style = {{ float: 'left' , height: '2em' , margin: '0.2em' }}
                    src = { iconKartenzahlung }
                  />
                ) : null}
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                {
                  appParkhausschilder
                    .filter(
                      ( entry:any ) =>
                        {
                          if( entry[ 0 ] === parkplatz[ 16 ] ) {
                            return true ;
                          }
                        }
                    )
                    .map(
                      ( entry:any , index:number ) =>
                        {
                          return <IonImg key = { 'pschild_' + index } src={ entry[ 1 ] } />
                        }
                    )
                }
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol
                style =
                  {
                    {
                      textAlign: 'center'
                    }
                  }
              >
                <IonButton
                  onClick={() => {
                    setShowParkplatzModal(true);
                    setParkplatzAktiv(parkplatz[0]);
                  }}
                  size = "large"
                >
                  mehr Infos
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </Popup>
      )
    }

  const mkPopupBaustelle = ( baustelle:any ) =>
    {
      return(
        <Popup
          minWidth = { 200 }
        >
          <IonGrid
            style =
              {
                {
                  padding: '0'
                }
              }
          >
            <IonRow>
              <IonCol
                style =
                  {
                    {
                      color: 'red' ,
                      padding: '0'
                    }
                  }
              >
                Baustelle
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol
                style =
                  {
                    {
                      fontSize: '1.5em' ,
                      fontWeight: 'bold' ,
                      padding: '0'
                    }
                  }
              >
                { baustelle[2] }
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol
                style =
                  {
                    {
                      fontSize: '1.2em' ,
                      fontWeight: 'bold' ,
                      padding: '0'
                    }
                  }
              >
                { new Date( baustelle[ 3 ] ).toLocaleString().slice( 0 , -3 ).replace( ',' , '') } - { new Date( baustelle[ 4 ] ).toLocaleString().slice( 0 , -3 ).replace( ',' , '') }
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol
                style =
                  {
                    {
                      padding: '0'
                    }
                  }
              >
                { baustelle[ 8 ] }
              </IonCol>
            </IonRow>
          </IonGrid>
        </Popup>
      )
    }

  useEffect (()=>{
    let parkplaetzeServerTmp=parkplaetzeServer;
    let parkplaetzeAnzeigeTmp=Array();
    if(appFilterPKW){
      parkplaetzeServerTmp.forEach((parkplatz)=>{
        if(parkplatz[5]>0){
          parkplaetzeAnzeigeTmp.push(parkplatz)
        }
      })
    }
    if(appFilterLKW){
      parkplaetzeServerTmp.forEach((parkplatz)=>{
        if(parkplatz[6]>0){
          parkplaetzeAnzeigeTmp.push(parkplatz)
        }
        
      })
    }
    if(appFilterBehindert){
      parkplaetzeServerTmp.forEach((parkplatz)=>{
        if(parkplatz[10]>0){
          parkplaetzeAnzeigeTmp.push(parkplatz)
        }
        
      })
    }
    if(appFilterBusse){
      parkplaetzeServerTmp.forEach((parkplatz)=>{
        if(parkplatz[8]>0){
          parkplaetzeAnzeigeTmp.push(parkplatz)
        }
        
      })
    }
    if(appFilterElektro){
      parkplaetzeServerTmp.forEach((parkplatz)=>{
        if(parkplatz[9]>0){
          parkplaetzeAnzeigeTmp.push(parkplatz)
        }
        
      })
    }
    if(appFilterMotorraeder){
      parkplaetzeServerTmp.forEach((parkplatz)=>{
        if(parkplatz[11]>0){
          parkplaetzeAnzeigeTmp.push(parkplatz)
        }
        
      })
    }
    if(appFilterFahrraeder){
      parkplaetzeServerTmp.forEach((parkplatz)=>{
        if(parkplatz[15]>0){
          parkplaetzeAnzeigeTmp.push(parkplatz)
        }
        
      })
    }
    if(appFilterWoMo){
      parkplaetzeServerTmp.forEach((parkplatz)=>{
        if(parkplatz[7]>0){
          parkplaetzeAnzeigeTmp.push(parkplatz)
        }
        
      })
    }
    if(appFilterKartenzahlung){
      parkplaetzeAnzeigeTmp =
        parkplaetzeAnzeigeTmp
          .filter(
            ( parkplatz:any) =>
            {
              if( parkplatz[ 17 ] !== 'true' ) {
                return false ;
              }
              return true ;
            }
          )
      ;
    }
    var unique = Array.from(new Set(parkplaetzeAnzeigeTmp));
    // console.log( 'HITS:' , unique.length )
    setParkplaetzeAnzeige(unique) ;
    if( unique.length ) {
      // console.log( 'unique' , unique ) ;
      let my_bounds = Array() ;
      let max_lat = 0 ;
      let max_lng = 0 ;
      unique
        .forEach(
          ( entry:any ) =>
            {
              // console.log( 'unique[ 12 ]' , entry[ 12 ] ) ;
              let my_pos = JSON.parse( entry[ 12 ] ) ;
              if( my_pos.lat > max_lat ) {
                max_lat = my_pos.lat ;
              }
              if( my_pos.lng > max_lng ) {
                max_lng = my_pos.lng ;
              }
              my_bounds
                .push(
                  [
                    my_pos.lat ,
                    my_pos.lng
                  ]
                )
            }
        )
      // console.log( 'mapBoundsMax BEFORE' , mapBoundsMax ) ;
      if( mapBoundsMax && ( max_lat > mapBoundsMax[ 0 ] || max_lng > mapBoundsMax[ 1 ] ) ) {
        // console.log( 'REZOOM, PLEASE' ) ;
      }
      // console.log( 'my_bounds' , my_bounds ) ;
      setMapBounds( my_bounds ) ;
      // setMapCenter( [ 54.0938368 , 13.41 ] ) ;
    }
    else {
      // console.log( 'default bounds' ) ;
      // console.log( 'mapBoundsInitial' , mapBoundsInitial ) ;
      if( mapBoundsInitial ) {
        setMapBounds( mapBoundsInitial ) ;
      }
      // setMapCenter( mapSettings.leaflet.center ) ;
    }
  },[appFilterPKW,appFilterLKW,appFilterElektro,appFilterBehindert,appFilterWoMo,appFilterBusse,appFilterMotorraeder,appFilterFahrraeder,appFilterKartenzahlung,parkplaetzeServer])

  useEffect(() => {
    // console.log( 'setKarte' ) ;
    setTimeout(() => setRenderMap(true), 100);
  },[]);


  useEffect(() => {
    if( parkplatzAktiv ) {
      let my_data =
        parkplaetzeServer
          .filter(
            ( entry:any ) =>
              {
                return entry[ 0 ] === parkplatzAktiv
              }
          )
      ;
      setModalTitle( my_data[ 0 ][ 3 ] ) ;
      setModalContent( <ContentParkraum data = { my_data[ 0 ] } /> ) ;
    }
  }, [parkplatzAktiv]);

  useEffect(() => {
    if( tarifzoneAktiv ) {
      let my_data =
        TarifzonenServer
          .filter(
            ( entry:any ) =>
              {
                return entry[ 0 ] === tarifzoneAktiv
              }
          )
      ;
      setModalTarifzoneTitle( my_data[ 0 ][ 1 ] ) ;
      setModalTarifzoneContent( <ContentTarifzone data = { my_data[ 0 ] } /> ) ;
    }
  }, [tarifzoneAktiv]);

  useEffect(
    function setParkraeume()
      {
        if( appParkraeume.length > 0 ) {
          setParkplaetzeServer( appParkraeume );
        }
      } 
    ,
    [ appParkraeume ]
  ) ;

  useEffect(
    function setBaustellen()
      {
        if( appBaustellen.length > 0 ) {
          // console.log( 'BAUSTELLEN' , appBaustellen.length ) ;
          setBaustellenServer( appBaustellen );
        }
      } 
    ,
    [ appBaustellen ]
  ) ;

  useEffect(
    function setTarifzonen()
      {
        if( appTarife.length > 0 ) {
          let my_tarifzonen =
            appTarife
              .filter(
                ( entry:any ) =>
                  {
                    return entry[ 4 ] !== '[]' ;
                  }
              )
              .map(
                ( entry:any ) =>
                  {
                    return [ entry[ 0 ] , entry[ 2 ] , entry[ 3 ] , JSON.parse( entry[ 4 ] ) ] ;
                  }
              )
              .sort(
                ( a:any , b:any ) =>
                  {
                    if ( a[ 1 ] < b[ 1 ] ) { return 1 ; }
                    if ( a[ 1 ] > b[ 1 ] ) { return -1 ; }
                    return 0 ;
                  }
              )
          setTarifzonenServer( my_tarifzonen );
        }
      } 
    ,
    [ appTarife ]
  ) ;

  // s. https://gis.stackexchange.com/questions/127286/home-button-leaflet-map/127383
  // s. Suche "Home" in https://leafletjs.com/plugins.html
  const MapHomeButton = () =>
    {
      const context = useLeafletContext() ;
      const mapObject = context.map ;
      useEffect(
        () =>
          {
            if( ! document.getElementById( 'easyButtonHome' ) ) {
              L
                .easyButton(
                  svgFaHouse ,
                  function(
                    btn ,
                    map
                  )
                    {
                      map
                        .setView(
                          // @ts-ignore
                          mapSettings.leaflet.center ,
                          mapSettings.leaflet.initialZoom
                        )
                      ;
                    }
                    ,
                  'Startansicht' ,
                  'easyButtonHome'
                )
                .addTo( mapObject )
              ;
              // @ts-ignore
              let goal:any = document.getElementById( 'easyButtonHome' ).style
              goal.backgroundColor = 'white' ;
              goal.color = 'black' ;
              goal.lineHeight = '30px' ;
              goal.width = '30px' ;
            }
          }
        ,
        [ mapObject ]
      ) ;
      return null ;
    }

  /** vgl. MapHomeButton */
  const MapReloadButton = () =>
    {
      const context = useLeafletContext() ;
      const mapObject = context.map ;
      useEffect(
        () =>
          {
            if( ! document.getElementById( 'easyButtonReload' ) ) {
              L
                .easyButton(
                  svgFaUndo ,
                  function(
                    map
                  )
                    {
                      window.location.reload() ;
                    }
                    ,
                  'Neu laden' ,
                  'easyButtonReload'
                )
                .addTo( mapObject )
              ;
              // @ts-ignore
              let goal:any = document.getElementById( 'easyButtonReload' ).style
              goal.backgroundColor = 'white' ;
              goal.color = 'black' ;
              goal.lineHeight = '30px' ;
              goal.width = '30px' ;
            }
          }
        ,
        [ mapObject ]
      ) ;
      return null ;
    }

  // function MapChangeView( { center }:any ) {
  //   const map = useMap() ;
  //   map.setView( center ) ;
  //   return null;
  // }

  function MapChangeView( { bounds }:any ) {
    // console.log( 'bounds' , bounds ) ;
    const map = useMap() ;
    if( ! mapBoundsInitial ) {
      let curBounds = map.getBounds() ;
      setMapBoundsInitial(
        [
          // @ts-ignore
          [ curBounds._northEast.lat , curBounds._northEast.lng ] ,
          // @ts-ignore
          [ curBounds._southWest.lat , curBounds._southWest.lng ]
        ]
      ) ;
      // @ts-ignore
      setMapBoundsMax( [ curBounds._northEast.lat , curBounds._northEast.lng ] ) ;
    }
    // console.log( 'mapBoundsInitial' , mapBoundsInitial ) ;
    // console.log( 'mapBoundsMax' , mapBoundsMax ) ;
    if( bounds.length > 0 ) {
      map.fitBounds( bounds ) ;
    }
    return null;
  }

  useEffect(
    function showParkplaetze() {
      if( parkplaetzeAnzeige.length > 0 ) { 
        let my_parkplaetzeMarker =
          parkplaetzeAnzeige
            .map(
              ( parkplatz:any , index:number ) =>
                {
                  // console.log( 'Marker' , parkplatz[ 3 ] ) ;
                  let flaeche = JSON.parse(parkplatz[13]);
                  let AktIcon=parkplatzIcon;
                  if(parkplatz[4]==='Parkhaus'){
                    AktIcon=parkhausIcon;
                  }
                  if(parkplatz[4]==='Parkplatz'){
                    AktIcon=parkplatzIcon;
                  }
                  if(parkplatz[4]==='Stellplatz'){
                    AktIcon=stellplatzIcon;
                  }
                  return (
                    <>
                      <Marker key = { 'marker_' + index } icon={AktIcon} position={JSON.parse(parkplatz[12])}>
                        { mkPopupParkraum( parkplatz )}
                      </Marker>
                      {flaeche.length > 0 ? <Polygon positions={flaeche} >{ mkPopupParkraum( parkplatz )}</Polygon> : null}

                    </>
                  );
                }
              )
        ;
        setParkplaetzeMarker( my_parkplaetzeMarker )  ;
      }
      else {
        setParkplaetzeMarker( null ) ;
      }
    } ,
    [ parkplaetzeAnzeige ]
  ) ;

  useEffect(
    function showBaustellen() {
      if( appFilterBaustelle && BaustellenServer.length > 0 ) {
        // console.log( 'appFilterBaustelle TRUE' ) ;
        let my_baustellenMarker =
          BaustellenServer
            .map(
              ( baustelle:any , index:number ) => 
                {
                  // console.log( 'baustelle', ( index + 1 ) , 'von' , BaustellenServer.length ) ;
                  let flaeche = JSON.parse(baustelle[6]);
                  let AktIcon=baustelleIcon;
                  return (
                    <>
                      <Marker icon={AktIcon} position={JSON.parse(baustelle[5])}>
                        { mkPopupBaustelle( baustelle ) }
                      </Marker>
                      {flaeche.length > 0 ? <Polygon positions={flaeche} color={'#eb445a'}>{ mkPopupBaustelle( baustelle ) }</Polygon> : null}
                    </>
                  );
                }
              )
        ;
        setBaustellenMarker( my_baustellenMarker ) ; 
      }
      else {
        // console.log( 'appFilterBaustelle FALSE' ) ;
        setBaustellenMarker( null ) ; 
      }
    } ,
    [ appFilterBaustelle , BaustellenServer ]
  ) ;

  useEffect(
    function showTarifzonen() {
      if( appFilterTarifzonen && TarifzonenServer.length > 0 ) {
        // console.log( 'appFilterTarifzonen TRUE' ) ;
        let my_tarifzonenPolygons =
          TarifzonenServer
            .map(
              ( entry:any , index:number )=>
                {
                  // console.log( 'SHOW tarifzone' , entry[ 1 ] ) ;
                  return (
                    <Polygon
                      key = { 'tarifzone_' + index }
                      pathOptions = { { color: tarifzonenFarben[ index ] } }
                      positions = { entry[ 3 ] }
                    >
                      <Popup
                        minWidth = { 200 }
                      >
                        <IonGrid
                          style =
                            {
                              {
                                padding: '0'
                              }
                            }
                        >
                          <IonRow>
                            <IonCol
                              style =
                                {
                                  {
                                    fontSize: '1.5em' ,
                                    fontWeight: 'bold' ,
                                    padding: '0'
                                  }
                                }
                            >
                              { entry[ 1 ] }
                            </IonCol>
                          </IonRow>
                          <IonRow>
                            <IonCol
                              style =
                                {
                                  {
                                    textAlign: 'center'
                                  }
                                }
                            >
                              <IonButton
                                onClick={() => {
                                  setShowTarifzoneModal( true ) ;
                                  setTarifzoneAktiv( entry[ 0 ] ) ;
                                }}
                                size = "large"
                              >
                                mehr Infos
                              </IonButton>
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </Popup>
                    </Polygon>
                  ) ;
                }
            )
        setTarifzonenPolygons( my_tarifzonenPolygons ) ;
      }
      else {
        // console.log( 'appFilterTarifzonen FALSE' ) ;
        setTarifzonenPolygons( null ) ;
      }
    } ,
    [ appFilterTarifzonen ]
  ) ;

  return (
    <>
      { 
        renderMap
        &&
        <MapContainer
          key = 'leaflet_map'
          bounds = { mapBounds }
          // @ts-ignore
          center = { mapCenter }
          // @ts-ignore
          maxBounds= { mapSettings.leaflet.maxBounds }
          maxBoundsViscosity = { mapSettings.leaflet.maxBoundsViscosity }
          maxZoom = { mapSettings.leaflet.maxZoom }
          minZoom = { mapSettings.leaflet.minZoom }
          zoom= { mapSettings.leaflet.initialZoom }
          style={{ width: "100%", height: "50vh", position: "relative" }}
        >
          {/* <MapChangeView center = { mapCenter } /> */}
          <MapChangeView bounds = { mapBounds } />
          <MapHomeButton/>
          <MapReloadButton />
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors | Hinweis: Es besteht kein Anspruch auf Vollständigkeit und Gewähr.'
            url="https://karten.solufind.de/hot/{z}/{x}/{y}.png"
          />
          { parkplaetzeMarker }
          { tarifzonenPolygons }
          { baustellenMarker }
        </MapContainer>
      }
      <IonModal
        isOpen = { showParkplatzModal }
        onDidDismiss = { () => setShowParkplatzModal( false ) }
      >
        <IonHeader>
          <IonToolbar>
            <IonCardTitle>{ modalTitle }</IonCardTitle>
            <IonButton
              onClick =
                {
                  () => setShowParkplatzModal( false )
                }
              slot = "end"
            >
              <IonIcon
                icon = { closeOutline }
              />
            </IonButton>
          </IonToolbar>
        </IonHeader>
        <IonList
          style =
            {
              {
                overflow: 'auto'
              }
            }
        >
          { modalContent }
        </IonList>
      </IonModal>
      <IonModal  isOpen={ showTarifzoneModal } onDidDismiss = { () => setShowTarifzoneModal( false ) } >
        <IonHeader>
          <IonToolbar>
            <IonCardTitle>{ modalTarifzoneTitle }</IonCardTitle>
            <IonButton
              onClick =
                {
                  () => setShowTarifzoneModal( false )
                }
              slot = "end"
            >
              <IonIcon
                icon = { closeOutline }
              />
            </IonButton>
          </IonToolbar>
        </IonHeader>
        <IonList
          style =
            {
              {
                overflow: 'auto'
              }
            }
        >
          { modalTarifzoneContent }
        </IonList>
      </IonModal>
    </>  
  );
};

export default CompKarte;
