import {
  IonCol,
  IonGrid,
  IonItem,
  IonList,
  IonRow,
} from "@ionic/react";
import { FunctionComponent, useContext } from "react";
import { DataContext } from "../context/DataContext";
import ReactMarkdown from "react-markdown";
import remarkBreaks from "remark-breaks" ;
import remarkGfm from "remark-gfm";
import rehypeRaw from 'rehype-raw' ;

type ContentTarifzoneProps = {
  data:any
}

export const ContentTarifzone: FunctionComponent<ContentTarifzoneProps> = ( { data } ) => {
  // console.log( 'Capacitor.getPlatform()' , Capacitor.getPlatform() ) ;
  const {
  } = useContext(DataContext);

  return (
    <IonItem
      lines = "none"
    >
      <IonList>
        <IonGrid>
          <IonRow>
            <IonCol>
              <ReactMarkdown linkTarget = "_blank" rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkBreaks,remarkGfm]}>
                { data[ 2 ] }
              </ReactMarkdown>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonList>
    </IonItem>
  ) ;

}