import axios from "axios";

/**
 *  getDataByApi - Datenbezug von angegebenem Endpoint
 * 
 * @param apiUrl        - z.B. "https://datamehr-backend.saint-online.de/api/" 
 * @param apiCall       - z.B. "lieferanten/get_lieferanten.php?betriebe_id=" 
 * @param authToken     - optional: Authentifizierungstoken aus Login
 * @param setResponse   - Funktion zum Setzen der Fetch-Status-Meldung (typischerweise useState-Funktion)
 * @param setData       - Funktion zum Zwischenspeichern der gefetchten Daten (typischerweise useState-Funktion) 
 * @param setFetchtime  - Funktion zum Zwischenspeichern des letzten (erfolgreichen) Fetch-Zeitpunkts
 * @param sortData      - optional: Funktion zum Sortieren von response.data (in Array mit Objekten)
 *                        - NB: Komponenten wie DataGrid sortieren selbst
 *                        - ! ideal wenn tatsächlich alle Konsumenten die selbe Sortierung haben sollen
 * @param objOptions  - optional: JSON mit Optionen für GET-Backends mit entsprechender Unterstützung
 *                        - aktuell "{ fields: [ A, B, ... ] }" für Angabe gewünschter Felder (statt alle Felder)
 */
 export const getDataByApi = async ( apiUrl:string , apiCall:string , authToken:string = '' , setResponse:Function , setData:Function , setFetchtime:Function , sortData:any = '' , objOptions:any = '' ) => {
  setResponse( 100 ) ;
  axios(
    {
      method: 'post',
      url: apiUrl + apiCall ,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json' ,
        Authorization: `Bearer ${ authToken }`,
      },
      // data: JSON.stringify( { fields: [ 'tarif_kunde' ] } ) ,
      data: objOptions ? JSON.stringify( objOptions ) : '' ,
    }
  )
  .then( ( response ) =>
    { 
      setFetchtime( new Date().valueOf() ) ;
      if( response.data.length === 0 ) {
        setData( [] )
        setResponse( 204 ) ;
      } else {
        sortData === ''
          ?
            setData( response.data )
          :
            setData( sortData( response.data ) )
            setResponse( response.status ) ;
      }
    }
  )
  .catch( error =>
    { 
      setResponse( error.response.status ) ;
    }
  ) ;
}

