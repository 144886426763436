import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonItem,
  IonList,
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { DataContext } from "../context/DataContext";


const PageParkraeume = () => {
  const {
    appParkraeume
  } = useContext( DataContext ) ;

  const [ generatedEntries , setGeneratedEntries ] = useState( [ <span key = "entries_placeholder" ></span> ] );

  useEffect(
    function makeEntries()
      {
        if( appParkraeume.length > 0 ){
          let my_items =
            appParkraeume
              .map((entry:any)=>{
                return (
                  <IonItem 
                    key={'parkraum'+entry[0]} 
                    routerLink={'/parkraeume/'+entry[2]+'_'+entry[0]}
                  >
                    {entry[2]}
                  </IonItem>
                )
                }
              )
          ;
          setGeneratedEntries( my_items ) ;
        }
      }
    ,
    [ appParkraeume ]
  ) ;

  return (
    <>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>Parkräume</IonCardTitle>
        </IonCardHeader>

        <IonCardContent>
          <IonList>
            { generatedEntries }
          </IonList>
        </IonCardContent>
      </IonCard>
    </>
  );
};

export default PageParkraeume;
