import {
  IonCol,
  IonGrid,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
} from "@ionic/react";
import { FunctionComponent, useContext, useEffect, useState } from "react";
import { DataContext } from "../context/DataContext";
import ReactMarkdown from "react-markdown";
import remarkBreaks from "remark-breaks" ;
import remarkGfm from "remark-gfm";
import rehypeRaw from 'rehype-raw' ;
import axios from "axios";
import { Capacitor } from '@capacitor/core';
import iconAppleKarten from '../images/Apple-Karten_maps-96x96_2x.png' ;
import iconGoogleMaps from '../images/wikipedia_20200206211746_Google_Maps_icon_(2020).svg.png' ;
import iconKartenzahlung from "../images/icon_kartenzahlung.svg" ;

type ContentParkraumProps = {
  data:any
}

export const ContentParkraum: FunctionComponent<ContentParkraumProps> = ( { data } ) => {
  // console.log( 'Capacitor.getPlatform()' , Capacitor.getPlatform() ) ;
  const {
    appParkhausschilder ,
    appTarife ,
    appParkraeumeTarife
  } = useContext(DataContext);

  const [ currentParkhausschild , setCurrentParkhausschild ]:any = useState( null ) ;
  const [ currentParkraum , setCurrentParkraum ] = useState( 0 ) ;
  const [ mapServicesLinks , setMapServicesLinks ] = useState( <span>laden ...</span> ) ;
  const [ parkraumAdresse , setParkraumAdresse ] = useState( 'laden ...' ) ;
  const [ parkraumBeschreibung , setParkraumBeschreibung ] = useState( 'laden ...' ) ;
  const [ parkraumOeffnungszeiten , setParkraumOeffnungszeiten ] = useState( 'laden ...' ) ;
  const [ parkraumPosition , setParkraumPosition ] = useState( { lat: 0 , lng: 0 } ) ;

  const makeStellplaetze = ( data:any ) =>
    {
      let fields =
        [
          5 , 6 , 7 , 8 , 9 , 10 , 11 , 15
        ]
      ;
      let labels =
        {
          5: 'PKW' ,
          6: 'LKW' ,
          7: 'Wohnmobile' ,
          8: 'Busse' ,
          9: 'Elektrofahrzeuge' ,
          10: 'behindertengerecht' ,
          11: 'Motorräder' ,
          15: 'Fahrräder'
        }
      ;
      let my_data =
        data
          .map(
            ( entry:any , index:number ) =>
              {
                if( fields.includes( index ) && entry > 0 ){
                  return (
                    <tr
                      key = { 'stellplaetze_' + index }
                      style =
                        {
                          {
                            backgroundColor: 'white'
                          }
                        }
                    >
                      <td>
                        {
                          // @ts-ignore
                          labels[ index ]
                        }
                      </td>
                      <td>
                        {
                          entry
                        }
                      </td>
                    </tr>
                  )            
                }
              }
          )
        ;
      return my_data ;
    }

  const makeMapServiceLink = ( service:string ) =>
    {
      let label = 'Google Maps' ;
      let src = iconGoogleMaps ;
      let urlStart = 'https://www.google.com/maps/dir/?api=1&destination=' ;
      let hinweis = 'Datenschutzhinweis: Mit Klick auf [ OK ] verlassen Sie unser Webangebot und werden auf die Seiten von ' + label + ' weitergeleitet' ;
      if( service === 'apple' ) {
        label = 'Apple Karten' ;
        src = iconAppleKarten ;
        urlStart = 'https://maps.apple.com/?saddr=here&daddr=' ;
        hinweis = 'DATENSCHUTZHINWEIS Apple - öffne ' + urlStart + parkraumPosition.lat + ',' + parkraumPosition.lng + ' in neuem Fenster' ;
      }
      return(
        <IonRow>
          <IonCol>
            <IonItem
              detail
              href = {
                // 'javascript:alert( "DATENSCHUTZHINWEIS ' + service + '");'
                'javascript: if( window.confirm( "' + hinweis + '" ) ){ void( window.open( "' + urlStart + parkraumPosition.lat + ',' + parkraumPosition.lng + '" , "_blank" ) ) }'
              }
              style =
              {
                {
                  '--padding-start': 0 ,
                }
              }
        >
              <IonImg
                slot = "start"
                src = { src }
                style =
                  {
                    {
                      width: '32px' ,
                      marginInlineEnd: '16px'
                    }
                  }
              />
              <IonLabel>
                { label }
              </IonLabel>
            </IonItem>
          </IonCol>
        </IonRow>
      )
    }
  ;

  useEffect(
    function openInMapServices()
      {
        if( parkraumPosition.lat !== 0 ) {
          let my_links =
            <>
              {
                Capacitor.getPlatform() === 'ios'
                  ?
                    makeMapServiceLink( 'apple' )
                  :
                    null
              }
              {
                makeMapServiceLink( 'google' )
              }
            </>
          ;
          setMapServicesLinks( my_links ) ;
        }
      }
    ,
    [ parkraumPosition ]
  ) ;
        
  const makeParkraeumeTarife = ( parkraum_id:string ) =>
    {
      let my_output = 'k.A.' ;
      let my_data =
        appParkraeumeTarife
          .filter(
            ( entry:any ) =>
              {
                return entry.parkraum_tarif_parkraum === parkraum_id ;
              }
          )
          .map(
            ( entry:any ) =>
              {
                return entry.parkraum_tarif_tarif ;
              }
          )
      ;
      if( my_data.length === 0 ) {
        return my_output ;
      }
      let my_tarife_arr =
        appTarife
          .filter(
            ( entry:any ) =>
              {
                return my_data.includes( entry[ 0 ] )
              }
          )
      if( my_tarife_arr.length === 0 ) {
        return my_output ;
      }
      let my_tarife_obj:any = {} ;
      my_tarife_arr
        .forEach(
          ( entry:any ) =>
            {
              my_tarife_obj[ entry[ 0 ] ] = {
                bezeichnung: entry[ 2 ] ,
                text: entry[ 3 ]
              } ;
            }
        )
      my_output =
        my_data
          .map(
            ( entry:any ) =>
              {
                if( ! Object.hasOwn( my_tarife_obj , entry ) ) {
                  return null ;
                }
                return(
                  <IonGrid
                    key = { 'tarif_' + entry }
                  >
                    <IonRow>
                      <IonCol>
                        <h4>
                          { my_tarife_obj[ entry ].bezeichnung }
                        </h4>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol >
                        <ReactMarkdown linkTarget = "_blank" rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkBreaks,remarkGfm]}>
                          { my_tarife_obj[ entry ].text }
                        </ReactMarkdown>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                )
              }    
          )
        ;
      return my_output ;
    }

  useEffect(
    function getParkraumDetails()
      {
        if( currentParkraum !== 0 ) {
          axios.get(`https://api.parkraumpilot.de/api/parkraeume/get_parkraum.php?parkraum_id=` + data[ 0 ] )
            .then(res => {
              let axios_response  = res.data;
              // console.log( 'axios_response.data[ 0 ]' , axios_response.data[ 0 ] ) ;
              if( axios_response.data[ 0 ][ 'parkraum_adresse' ] ) {
                setParkraumAdresse( axios_response.data[ 0 ][ 'parkraum_adresse' ] ) ;
              } else {
                setParkraumAdresse( 'k.A.' ) ;
              }
              if( axios_response.data[ 0 ][ 'parkraum_beschreibung' ] ) {
                setParkraumBeschreibung( axios_response.data[ 0 ][ 'parkraum_beschreibung' ] ) ;
              } else {
                setParkraumBeschreibung( 'k.A.' ) ;
              }
              if( axios_response.data[ 0 ][ 'parkraum_oeffnungszeiten' ] ) {
                setParkraumOeffnungszeiten( axios_response.data[ 0 ][ 'parkraum_oeffnungszeiten' ] ) ;
              } else {
                setParkraumOeffnungszeiten( 'k.A.' ) ;
              }
              if( axios_response.data[ 0 ][ 'parkraum_position' ] ) {
                setParkraumPosition( JSON.parse( axios_response.data[ 0 ][ 'parkraum_position' ] ) ) ;
              }
          })
        }
      }
      ,
      [ currentParkraum ]
  ) ;
  
  useEffect(
    function init()
      {
        setCurrentParkraum( data[ 0 ] ) ;
      }
      ,
      [ data ]
  ) ;
  
  useEffect(
    function ParkhausSchild()
      {
        let my_parkhausschild =
          appParkhausschilder
            .filter(
              ( entry:any ) =>
                {
                  if( entry[ 0 ] === data[ 16 ] ) {
                    return true ;
                  }
                }
            )
        if( my_parkhausschild.length > 0 ) {
          setCurrentParkhausschild(
            <IonItem
              lines = "none"
            >
              <IonImg
                src = { my_parkhausschild[ 0 ][ 1 ] }
              />
            </IonItem>
          ) ;
        }
        else {
          setCurrentParkhausschild( null ) ;
        }
      }
      ,
      [ data ]
  ) ;
  
  return (
    <>
      { currentParkhausschild }
      {data[17] === 'true' ? (
        <IonItem
          lines = "none"
        >
          <IonImg
              style = {{ float: 'left' , height: '2em' , margin: '0.2em' }}
              src = { iconKartenzahlung }
            />
        </IonItem>
      ) : null}
      <IonItem
        lines = "none"
      >
        <h3>
          Typ
        </h3>
      </IonItem>
      <IonItem
        lines = "none"
      >
        <IonList style = { { width: '100%' } }>
          <IonGrid>
            <IonRow>
              <IonCol>
                { data[ 4 ] }
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonList>
      </IonItem>
      <IonItem
        lines = "none"
      >
        <h3>
          Adresse
        </h3>
      </IonItem>
      <IonItem
        lines = "none"
      >
        <IonList style = { { width: '100%' } }>
          <IonGrid>
            <IonRow>
              <IonCol>
                <ReactMarkdown linkTarget = "_blank" rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkBreaks,remarkGfm]}>
                  { parkraumAdresse }
                </ReactMarkdown>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonList>
      </IonItem>
      <IonItem
        lines = "none"
      >
        <h3>
          Anzahl Stellplätze
        </h3>
      </IonItem>
      <IonItem
        lines = "none"
      >
        <IonList style = { { width: '100%' } }>
          <IonGrid>
            <IonRow>
              <IonCol>
                <table
                  style =
                    {
                      {
                        width: '15em'
                      }
                    }
                >
                  <tbody>
                    { makeStellplaetze( data) }
                  </tbody>
                </table>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonList>
      </IonItem>
      <IonItem
        lines = "none"
      >
        <h3>
          Öffnungszeiten
        </h3>
      </IonItem>
      <IonItem
        lines = "none"
      >
        <IonList style = { { width: '100%' } }>
          <IonGrid>
            <IonRow>
              <IonCol>
                <ReactMarkdown linkTarget = "_blank" rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkBreaks,remarkGfm]}>
                  { parkraumOeffnungszeiten }
                </ReactMarkdown>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonList>
      </IonItem>
      <IonItem
        lines = "none"
      >
        <h3>
          Beschreibung
        </h3>
      </IonItem>
      <IonItem
        lines = "none"
      >
        <IonList style = { { width: '100%' } }>
          <IonGrid>
            <IonRow>
              <IonCol>
                <ReactMarkdown linkTarget = "_blank" rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkBreaks,remarkGfm]}>
                  { parkraumBeschreibung }
                </ReactMarkdown>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonList>
      </IonItem>
      <IonItem
        lines = "none"
      >
        <h3>
          Tarife & Gebühren
        </h3>
      </IonItem>
      <IonItem
        lines = "none"
      >
        <IonList style = { { width: '100%' } }>
          { makeParkraeumeTarife( data[ 0 ]) }
        </IonList>
      </IonItem>
      <IonItem
        lines = "none"
      >
        <h3>
          Navigation
        </h3>
      </IonItem>
      <IonItem
        lines = "none"
      >
        <IonList style = { { width: '100%' } }>
          <IonGrid>
            <IonRow>
              <IonCol>
                <p>
                Wenn auf Ihrem Gerät eine App-Version des Kartenanbieters installiert ist, dann wird diese gestartet, ansonsten die Web-Version im Browser.
                </p>
              </IonCol>
            </IonRow>
            { mapServicesLinks }
          </IonGrid>
        </IonList>
      </IonItem>
   </>
  ) ;

}