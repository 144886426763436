import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonIcon,
  IonRow,
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { DataContext } from "../context/DataContext";
import { chevronForward } from "ionicons/icons";
import axios from "axios";

const appSettings =
  {
    kunde:
      {
        id: 4
      }
  }

const PageNews = () => {
  const {
  } = useContext( DataContext ) ;

  const [ doFetch , setDoFetch ]:any = useState() ;
  const [ generatedEntries , setGeneratedEntries ] = useState( <span key = "entries_placeholder" ></span> );
  const [ newsAll , setNewsAll ] = useState( [] ) ;

  useEffect(
    function init() {
      setDoFetch( true ) ;
    }
    ,
    []
  )

  useEffect(
    function reset_appNewsItems() {
      // console.log( 'reset_appNewsItems') ;
      if( doFetch ) {
        // console.log( 'reset_appNewsItems DO') ;
        setDoFetch( false ) ;
        axios
          .get(
            'https://api.parkraumpilot.de/api/artikel/get_artikel_liste.php'
            + '?kunde_id=' + appSettings.kunde.id
            + '&no_limit=1'
          )
          .then((res) => {
            let axios_response = res.data;
            setNewsAll( axios_response.data ) ;
          }
        );
      }
    }
    ,
    [ doFetch ]
  ) ;

  useEffect(
    function makeEntries()
      {
        if( newsAll.length > 0 ){
          let my_items =
            newsAll
              .map(
                ( entry:any ) =>
                  {
                    return(
                      <IonCol
                        key =  { 'news_' + entry[ 0 ] }
                        size-md="6"
                        sizeLg='4'
                      >
                        <IonCard
                          href={ '/news/' + encodeURIComponent( entry[ 2 ] ) + '_' + entry[ 0 ] }
                        >
                          <div style =
                            {
                              {
                                background: "url('"+entry[ 5 ]+"') no-repeat",
                                backgroundSize:'100% auto',
                                backgroundPosition: "center center",
                                height:'250px',
                                minHeight:'250px',
                                maxHeight:'250px',
                              }
                            }>
                              &nbsp;
                          </div>
                          <div
                            style =
                              {
                                {
                                  minHeight: '150px' ,
                                  maxHeight: '150px' ,
                                  overflow: 'hidden'
                                }
                              }
                          >
                            <IonCardHeader>
                              <IonCardTitle>{ entry[ 2 ] }</IonCardTitle>
                              <IonCardSubtitle>{ new Date( entry[ 3 ] ).toLocaleDateString() }</IonCardSubtitle>
                            </IonCardHeader>
                            <IonCardContent>
                              &nbsp;
                            </IonCardContent>
                          </div>
                          <IonButton
                            color="tertiary"
                            expand="block"
                            routerLink = { '/news/' + encodeURIComponent( entry[ 2 ] ) + '_' + entry[ 0 ] }
                          >
                            weiterlesen
                            <IonIcon slot="end" icon={chevronForward}></IonIcon>
                          </IonButton>
                        </IonCard>
                      </IonCol>
                    )
                  }
                )
          ;
          setGeneratedEntries(
            <IonGrid>
              <IonRow>
                { my_items }
              </IonRow>
            </IonGrid>
          ) ;
        }
      }
    ,
    [ newsAll ]
  ) ;

  return (
    <>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>News</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          { generatedEntries }
        </IonCardContent>
      </IonCard>
    </>
  );
};

export default PageNews;
