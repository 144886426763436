import { useContext, useState } from "react";
import { DataContext } from "./DataContext";
import { useEffect } from "react";
import axios from "axios";

const appSettings =
  {
    kunde:
      {
        id: 4
      }
  }


const AppData: any = () => {
  const { appBaustellenChange , appMenueItemsChange , appNewsItemsChange , appParkhausschilderChange , appParkraeumeChange , appStellplaetzeGivenChange , appTarifeChange , appParkraeumeTarifeChange } = useContext(DataContext);

  const [ updateTimerParkhausschilder , setUpdateTimerParkhausschilder ] = useState( 0 ) ;

  useEffect(
    function resetUpdateTimerParkhausschilder() {
      // console.log( 'resetUpdateTimerParkhausschilder' , new Date().toTimeString() ) ;
      setTimeout( () => { setUpdateTimerParkhausschilder( Math.random ) ; } , 300000 ) ;
    }
    ,
    [ updateTimerParkhausschilder ]
  )
  ;

  useEffect(
    function getParkraeume() {
      axios
        .get( `https://api.parkraumpilot.de/api/parkraeume/get_parkraeume.php?kunde_id=` + appSettings.kunde.id )
        .then(
          ( res ) =>
            {
              let axios_response = res.data;
              appParkraeumeChange(
                axios_response.data
                .sort(
                  ( a:any , b:any ) =>
                    {
                      if ( a[ 2 ] > b[ 2 ] ) { return 1 ; }
                      if ( a[ 2 ] < b[ 2 ] ) { return -1 ; }
                      return 0 ;
                    }
                )
              ) ;
              appStellplaetzeGivenChange( axios_response.stellplaetze_given ) ;
            }
        )
      ;
    }
    ,
    []
  )
  ;

  useEffect(
    function getBaustellen() {
      axios
        .get( `https://api.parkraumpilot.de/api/baustellen/get_baustellen.php?kunde_id=` + appSettings.kunde.id )
        .then(
          ( res ) =>
            {
              let axios_response = res.data;
              appBaustellenChange( axios_response.data ) ;
            }
        )
      ;
    }
    ,
    []
  )
  ;

  useEffect(() => {
    axios
      .get(`https://api.parkraumpilot.de/api/seiten/get_seiten.php?kunde_id=` + appSettings.kunde.id)
      .then((res) => {
        let axios_response = res.data;
        // console.log("axios_response", axios_response);
        appMenueItemsChange(axios_response.data);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`https://api.parkraumpilot.de/api/artikel/get_artikel_liste.php?kunde_id=` + appSettings.kunde.id)
      .then((res) => {
        let axios_response = res.data;
        // console.log("axios_response", axios_response);
        appNewsItemsChange(axios_response.data);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`https://api.parkraumpilot.de/api/tarife/get_tarife.php?kunde_id=` + appSettings.kunde.id)
      .then((res) => {
        let axios_response = res.data;
        // console.log("axios_response", axios_response);
        appTarifeChange(
          axios_response.data
            .sort(
              ( a:any , b:any ) =>
                {
                  if ( a[ 2 ] > b[ 2 ] ) { return 1 ; }
                  if ( a[ 2 ] < b[ 2 ] ) { return -1 ; }
                  return 0 ;
                }
            )
        );
      });
  }, []);

  useEffect(
    function getParkhausschilder() {
      // console.log( 'getParkhausschilder ' ) ;
      axios
        .get( `https://api.parkraumpilot.de/api/parkraeume/get_pls_inet_data.php?as=pngDataURIs`)
        .then(
          ( res ) =>
            {
              let axios_response = res.data;
              appParkhausschilderChange( axios_response.data ) ;
            }
        )
      ;
    }
    ,
    [ updateTimerParkhausschilder ]
  )
  ;

  useEffect(
    function getParkraeumeTarife() {
      // console.log( 'getParkhausschilder ' ) ;
      axios
        .get( `https://api.parkraumpilot.de/api/parkraeume/get_parkraum_tarife.php?kunde_id=` + appSettings.kunde.id )
        .then(
          ( res ) =>
            {
              let axios_response = res.data;
              appParkraeumeTarifeChange( axios_response.data ) ;
            }
        )
      ;
    }
    ,
    []
  )
  ;

};
export default AppData;
