import { Link, Redirect, Route , Switch } from "react-router-dom";
import {
  IonApp,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonImg,
  IonItem,
  IonMenuToggle,
  IonPage,
  IonRouterOutlet,
  IonRow,
  setupIonicReact,
} from "@ionic/react";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import { Autoplay } from "swiper";
import "@ionic/react/css/ionic-swiper.css";

import logoGpg from "./images/logo.png";


import { IonReactRouter } from "@ionic/react-router";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import PageKarte from "./pages/PageKarte";
import { useEffect, useState } from "react";

import { DataContext } from "./context/DataContext";
import AppContext from "./context/AppContext";
import PageInhalt from "./pages/PageInhalt";
import CompMenu from "./components/CompMenu";
import PageTarife from "./pages/PageTarife";
import PageParkraeume from "./pages/PageParkraeume";

import { isPlatform } from '@ionic/react';
import PageNews from "./pages/PageNews";

setupIonicReact(
  {
    mode: 'ios' ,
  }
);

const App: React.FC = () => {
  const [appParkraeume, setAppParkraeume] = useState([]);
  const [appStellplaetzeGiven, setAppStellplaetzeGiven] = useState([]);
  const [appBaustellen, setAppBaustellen] = useState([]);
  const[ appParkhausschilder , setAppParkhausschilder ] = useState( [] ) ;
  const [appMenueItems, setAppMenueItems] = useState([]);
  const[ appNewsItems , setAppNewsItems ] = useState( [] ) ;
  const [appTarife, setAppTarife] = useState([]);
  const [appParkraeumeTarife, setAppParkraeumeTarife] = useState([]);

  const [appFilterPKW, setAppFilterPKW] = useState(true);
  const [appFilterLKW, setAppFilterLKW] = useState(false);
  const [appFilterWoMo, setAppFilterWoMo] = useState(false);
  const [appFilterBusse, setAppFilterBusse] = useState(false);
  const [appFilterElektro, setAppFilterElektro] = useState(false);
  const [appFilterBehindert, setAppFilterBehindert] = useState(false);
  const [appFilterMotorraeder, setAppFilterMotorraeder] = useState(false);
  const [appFilterFahrraeder, setAppFilterFahrraeder] = useState(false);
  const [appFilterKartenzahlung, setAppFilterKartenzahlung] = useState(false);
  const [appFilterBaustelle, setAppFilterBaustelle] = useState(true);
  const [appFilterTarifzonen, setAppFilterTarifzonen] = useState( false );
  const [ swiperGallery , setSwiperGallery ] = useState( <div></div> );

  let dataContextValue = {
    appParkraeume: appParkraeume,
    appParkraeumeChange: setAppParkraeume,
    appStellplaetzeGiven: appStellplaetzeGiven,
    appStellplaetzeGivenChange: setAppStellplaetzeGiven,
    appBaustellen: appBaustellen,
    appBaustellenChange: setAppBaustellen,
    appParkhausschilder: appParkhausschilder,
    appParkhausschilderChange: setAppParkhausschilder,
    appMenueItems: appMenueItems,
    appMenueItemsChange: setAppMenueItems,
    appNewsItems: appNewsItems,
    appNewsItemsChange: setAppNewsItems,
    appTarife: appTarife,
    appTarifeChange: setAppTarife,
    appParkraeumeTarife: appParkraeumeTarife,
    appParkraeumeTarifeChange: setAppParkraeumeTarife,

    appFilterPKW: appFilterPKW,
    appFilterPKWChange: setAppFilterPKW,

    appFilterLKW: appFilterLKW,
    appFilterLKWChange: setAppFilterLKW,
    appFilterWoMo: appFilterWoMo,
    appFilterWoMoChange: setAppFilterWoMo,
    appFilterBusse: appFilterBusse,
    appFilterBusseChange: setAppFilterBusse,
    appFilterElektro: appFilterElektro,
    appFilterElektroChange: setAppFilterElektro,
    appFilterBehindert: appFilterBehindert,
    appFilterBehindertChange: setAppFilterBehindert,
    appFilterMotorraeder: appFilterMotorraeder,
    appFilterMotorraederChange: setAppFilterMotorraeder,
    appFilterFahrraeder: appFilterFahrraeder,
    appFilterFahrraederChange: setAppFilterFahrraeder,
    appFilterKartenzahlung: appFilterKartenzahlung,
    appFilterKartenzahlungChange: setAppFilterKartenzahlung,
    appFilterBaustelle: appFilterBaustelle,
    appFilterBaustelleChange: setAppFilterBaustelle,

    appFilterTarifzonen: appFilterTarifzonen,
    appFilterTarifzonenChange: setAppFilterTarifzonen,

  };

  const routeParkhausschild = ( pls_name:string ) =>
    {
      let my_parkraum =
        appParkraeume
          .filter(
            ( entry:any ) =>
              {
                if( entry[ 16 ] === pls_name ) {
                  return true ;
                }
              }
          )
      if( my_parkraum.length > 0 ) {
        return '/parkraeume/' + my_parkraum[ 0 ][ 2 ] + '_'+my_parkraum[ 0 ][ 0 ] ;
      }
      return '' ;
    }

  useEffect(
    function initSwiperGallery() {
      if( appParkhausschilder.length > 0 ) {
        let my_swiperGallery =
          <Swiper
            loop={true}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
              pauseOnMouseEnter: false,
            }}
            slidesPerView={2}
            modules={[Autoplay]}
            className="mySwiper"
          >
            {
              appParkhausschilder
                .map(
                  ( entry:any , index:number ) =>
                    {
                      return(
                        <SwiperSlide key = { 'park_' + index } >
                          {
                            routeParkhausschild( entry[ 0 ] )
                              ?  
                                <Link to = { routeParkhausschild( entry[ 0 ] ) }><IonImg style={{ paddingRight: "1vw" }} src={ entry[ 1 ] } /></Link>
                              :
                                <IonImg style={{ paddingRight: "1vw" }} src={ entry[ 1 ] } />
                          }
                        </SwiperSlide>
                      )
                    }
                  )
            }
          </Swiper>
        ;
        setSwiperGallery( my_swiperGallery ) ;
      }
    }
    ,
    [ appParkhausschilder ]
  )
  
  return (
    <DataContext.Provider value={dataContextValue}>
      <AppContext />
      <IonApp
        style =
          {
            {
              overflowWrap: 'normal'
            }
          }
      >
      <IonReactRouter>
        <IonHeader style = {{ backgroundColor: 'var(--ion-color-primary )' , paddingTop: isPlatform('ios') && ! document.URL.startsWith( 'http' ) ? '22px' : 'inherit' }}>
              <IonGrid>
                <IonRow>
                  <IonCol
                   style={{maxHeight:'90px'}}
                    size-sm = "6"
                    size-xs = "12"
                  >
              <IonItem color="primary" style = {{ height: '100%'}} lines="none">
                <IonMenuToggle>
                  <FontAwesomeIcon
                    icon={faBars}
                    size="2x"
                    style={{ paddingRight: "1vw" }}
                  />
                </IonMenuToggle>

                <Link to = '/Karte' >
                  <IonImg src={logoGpg} />
                </Link>

              </IonItem>
              </IonCol>
              <IonCol
                size-sm = "6"
              >
                <IonItem color="primary" lines="none">
              { swiperGallery }
              </IonItem>
              </IonCol>
              </IonRow>
              </IonGrid>
        </IonHeader>
        <IonContent>
            <CompMenu />
            <IonPage id="main-content">
              <IonContent color="light">
                <IonRouterOutlet style={{ overflow: "auto" }}>
                <Redirect exact from="/" to="/Karte" />
                <Switch>
                  <Route exact path="/Karte" component={PageKarte} />
                  <Route exact path="/News" component = { PageNews } />
                  <Route exact path="/Parkraeume" component = { PageParkraeume } />
                  <Route exact path="/Tarife" component = { PageTarife } />
                  <Route path="*_:id" component={PageInhalt} />
                  <Route path="*" component={PageInhalt} />
                  {/* <Route path="" component={PageInhalt} /> */}
                  {/* { generatedRoutes } */}
                </Switch>
                </IonRouterOutlet>
              </IonContent>
            </IonPage>
        </IonContent>
        </IonReactRouter>
      </IonApp>
    </DataContext.Provider>
  );
};

export default App;
