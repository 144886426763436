import { IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonList, IonItem, IonGrid, IonRow, IonCol, IonButton } from "@ionic/react";
import { FunctionComponent, useEffect, useState } from "react";
import { getDataByApi } from "../functions/getDataByApi";

const appSettings =
  {
    kunde:
      {
        id: 4
      }
  }

type CompAnlagenProps = {
  typ: number ,
  zuordnung: number
}
export const CompAnlagen:FunctionComponent<CompAnlagenProps> = ( { typ = 0 , zuordnung = 0 } ) => {

  const [ anlagen , setAnlagen ]:any = useState( null ) ;
  const [ boolFetchAnew , setBoolFetchAnew ] = useState( false ) ;
  const [ intFetchGetTime , setIntFetchGetTime ] = useState( 0 ) ;
  const [ objFetchResult , setObjFetchResult ] = useState( { status: 100 , msg: '' , fieldname: [] , data: [] } ) ;

  useEffect(
    function initComponent() {
      // console.log( 'typ[]' , typ ) ;
      // console.log( 'zuordnung[]' , zuordnung ) ;
      setAnlagen( null ) ;
    }
    ,
    []
  ) ;

  useEffect(
    function initData() {
      // console.log( 'typ[ typ , zuordnung ]' , typ ) ;
      // console.log( 'zuordnung[ typ , zuordnung ]' , zuordnung ) ;
      setAnlagen( null ) ;
      if( typ > 0 && zuordnung > 0 ) {
        // console.log( '[ typ , zuordnung ]: TRUE' ) ;
        setBoolFetchAnew( true ) ;
      } else {
        // console.log( '[ typ , zuordnung ]: FALSE' ) ;
        setBoolFetchAnew( false ) ;
      }
    }
    ,
    [ typ , zuordnung ]
  ) ;

  useEffect(
    function getAnlagen() {
      if( boolFetchAnew) {
        getDataByApi(
          'https://api.parkraumpilot.de/api/dokumente/' ,
          'get_dokumente.php'
            + '?kunde_id=' + appSettings.kunde.id
            + '&verwendung_typ=' + typ
            + '&verwendung_zuordnung=' + zuordnung
          ,
          '' ,
          () => {} ,
          setObjFetchResult ,
          setIntFetchGetTime ,
        ) ;
      }
      setBoolFetchAnew( false ) ;
    } ,
    [ boolFetchAnew ]
  ) ;

  useEffect(
    function renewView() {
      if( objFetchResult.data.length > 0 ) {
        let my_anlagen =
          <IonGrid>
            <IonRow>
              <IonCol>
                <h3>
                  Weitere Informationen (PDF)
                </h3>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonList>
                  {
                    objFetchResult.data
                      .map(
                        ( entry:any ) =>
                          {
                            return(
                              <IonItem
                                href = { 'https://api.parkraumpilot.de/api/dokumente/print_dokument.php?dokument_id=' + entry[ 0 ] }
                                target = "_blank"
                              >
                                { entry[ 1 ] }
                              </IonItem>
                            ) ;
                          }
                      )
                  }
                </IonList>
              </IonCol>
            </IonRow>
          </IonGrid>
        ;
        setAnlagen( my_anlagen ) ;
      }
    } ,
    [ intFetchGetTime ]
  ) ;

  return anlagen ;
}