import { IonContent, IonHeader, IonItem, IonLabel, IonList, IonMenu, IonMenuToggle, IonTitle, IonToolbar } from '@ionic/react';
import { useContext, useEffect, useState } from 'react';
import { DataContext } from '../context/DataContext';
import CompFavorites from './CompFavorites';

interface ContainerProps { }

const CompMenu: React.FC<ContainerProps> = () => {
  const {
    appMenueItems
} = useContext(DataContext);

  const [ generatedMenueItems , setGeneratedMenueItems ] = useState( [ <span key = "items_placeholder" ></span> ] );

  useEffect(
    function makeMenuItems()
      {
        if( appMenueItems.length > 0 ){
          let my_categories:any = [] ;
          let my_items =
            appMenueItems
              .map((menueItem:any)=>{
                // console.log( 'make MenuItem' , menueItem[2] ) ;
                let my_key = 'key_' + menueItem[ 0 ] ;
                let my_routerLink = '/' + menueItem[ 2 ] + '_' + menueItem[ 0 ] ;
                let my_title = menueItem[ 2 ] ;
                if( menueItem[ 9 ] ){
                  if( ! my_categories.includes( menueItem[ 9 ] ) ) {
                    my_categories.push( menueItem[ 9 ] ) ;
                    my_key = 'key_' + encodeURIComponent( menueItem[ 9 ] ) ;
                    my_routerLink = '/' + menueItem[ 9 ] + '/' ;
                    my_title = menueItem[ 9 ] + ' ...' ;
                  }
                  else {
                    my_key = '' ;
                  }
                }
                if( my_key !== '' ) {
                  return (
                    <IonItem 
                      key = { my_key } 
                      routerLink = { my_routerLink }
                      >
                        { my_title }
                    </IonItem>
                  )
                }
                else {
                  return null ;
                }
              }
              )
          ;
          setGeneratedMenueItems( my_items ) ;
        }
      }
    ,
    [ appMenueItems ]
  ) ;

  return (
    <IonMenu type="overlay" contentId="main-content" swipeGesture = { false }>
    <IonHeader>
      <IonToolbar>
      <IonMenuToggle>
        
      
        <IonTitle>schließen</IonTitle></IonMenuToggle> 
      </IonToolbar>
    </IonHeader>
    <IonContent key = { 'random_' + new Date().valueOf() } >
       <IonMenuToggle>
        <IonList>
       
          <IonItem routerLink={'/Karte'} key="kartelist">Karte</IonItem>
          <IonItem routerLink={'/Parkraeume'} key="parkraeume">Parkräume</IonItem>
          <IonItem routerLink={'/Tarife'} key="tarife">Tarife & Gebühren</IonItem>
          <IonItem routerLink = { '/News' } key="news">News</IonItem>
       
        { generatedMenueItems }

       </IonList> </IonMenuToggle>
       <CompFavorites />
       <IonItem key="version">
                            <IonLabel>Version 1.0.009.4482</IonLabel>
                        </IonItem>

    </IonContent>
  </IonMenu>
  );
};

export default CompMenu;
